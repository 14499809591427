export default {
  header: {
    fontFamily: 'Gilroy',
    fontSize: 24,
    fontWeight: 'bold',
    marginBottom: 16,
    color: '#000000'
  },
  logoFileName: {
    color: '#787B80',
    fontFamily: 'Gilroy',
    fontSize: 10,
    marginLeft: 16,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  uploadIcon: {
    height: 32,
    width: 32
  },
  fullArea: {
    width: '100%',
    height: '100%'
  },
  linkV2: {
    color: '#2663FF',
    fontFamily: 'Inter',
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
    lineHeight: '20px'
  },
  link: {
    color: '#3577D4',
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 'bold'
  },
  iconWrapper: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex'
  },
  uploadText: {
    color: '#0B1829',
    fontFamily: 'Gilroy',
    fontSize: 14,
    marginBottom: 4,
    textAlign: 'center',
    lineHeight: 1.5
  },
  uploadTextV2: {
    width: 'auto',
    color: '#0B1829 !important',
    textAlign: 'center'
  },
  logoName: {
    color: '#787B80',
    fontFamily: 'Gilroy',
    fontSize: 14
  },
  replace: {
    fontFamily: 'Gilroy',
    fontSize: 14,
    fontWeight: 'bold',
    cursor: 'pointer',
    color: ({ disabled }) => (disabled ? '#787b80' : '#3577D4')
  },
  colorList: {
    '& p': {
      color: '#000000',
      fontFamily: 'Gilroy',
      fontSize: 16,
      marginTop: 8
    }
  },
  decorationEditSection: {
    paddingTop: 12,
    paddingBottom: 12,
    borderBottom: '1px solid #E8E9EB',
    width: '100%'
  },
  logoContainer: {
    height: 24,
    maxWidth: 124
  },
  logo: {
    height: '100%',
    objectFit: 'contain',
    maxWidth: '155px'
  },
  logoV2: {
    height: '140px',
    maxHeight: '140px',
    objectFit: 'contain',
    maxWidth: '100% !important'
  },
  logoPDF: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
    maxWidth: '100% !important',
    color: '#125CFF'
  },
  fileFormats: {
    color: '#787B80',
    fontFamily: 'Inter',
    fontSize: 12,
    textAlign: 'center',
    width: '60%',
    lineHeight: 1.5
  },
  fileFormatsV2: {
    textAlign: 'center',
    color: '#989EA4',
    marginTop: 8
  },
  uploadWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 200
  },
  ImageUploadContainer: {
    // height: 228,
    border: '1px solid #E5E7E8',
    borderRadius: 5,
    padding: ' 0px 13px'
  },
  ImageUploadContainerV2: {
    width: '100%',
    maxWidth: '100%',
    border: '1px dotted #E5E7E8',
    borderRadius: 5,
    padding: ' 0px 13px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    width: '100%',
    height: 'auto',
    border: '1px solid #E5E7E8',
    borderRadius: '5px'
  },
  imgPreview: {
    width: 'auto'
  },
  decorationNameWithEllipsis: {
    color: '#2663FF',
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    transition: 'all 0.3s ease'
  },
  imprintType: {
    fontFamily: 'Gilroy',
    color: '#787B80',
    fontSize: 14,
    marginBottom: 12
  },
  imprintTypeValue: {
    fontFamily: 'Gilroy',
    color: '#787B80',
    fontSize: 14,
    marginBottom: 0
  },
  delete: {
    fontFamily: 'Gilroy',
    color: '#2663FF',
    fontSize: 24
  },
  bottomSpacing: {
    width: '100%',
    height: 56
  },
  continueSection: {
    paddingBottom: 45,
    paddingTop: 18,
    backgroundColor: '#ffffff',
    position: 'sticky',
    bottom: 0
  },
  uploadNonSvgTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 24,
    color: '#000000',
    marginBottom: 14,
    maxWidth: 270
  },
  deleteWarningTitle: {
    fontFamily: 'Gilroy-SemiBold',
    fontSize: 20,
    color: '#000000',
    marginBottom: 12
  },
  uploadNonSvgExcerpt: {
    fontFamily: 'Gilroy-Medium',
    fontSize: 14,
    color: '#000000',
    marginBottom: 20
  },
  wizardButtons: {
    paddingLeft: 16,
    paddingRight: 16,
    fontFamily: 'Gilroy',
    fontSize: 14,
    minWidth: 132
  },
  panelBody: {
    display: ({ uploadWarning, deleteWarning }) => (uploadWarning || deleteWarning ? 'block' : 'contents'),
    position: 'relative',
    padding: 0
  },
  slideInView: {
    position: 'absolute',
    width: '100%',
    height: '100%'
  },
  newWindow: {
    paddingBottom: '2px !important'
  },
  notesWrapper: { paddingTop: 12, width: '100%' },
  proofSectionLabel: {
    fontSize: 14,
    color: '#4A4F54',
    lineHeight: 'normal',
    margin: '4px 0px 4px',
    width: '100%'
  },
  artworkContainer: {
    borderRadius: 5,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  artworkContainerFileName: {
    display: 'flex',
    paddingBottom: '4px',
    width: '100%',
    justifyContent: 'space-evenly',
    alignItems: 'center'
  },
  container384x284: {
    maxWidth: '100%',
    maxHeight: '100%',
    textAlign: 'center',
    position: 'relative'
  },
  snackbar: {
    position: 'fixed',
    transform: 'translateX(-50px)',
    '& .MuiSnackbarContent-root': {
      backgroundColor: '#e27171',
      color: '#fff',
      border: '1px solid #C62828',
      borderRadius: '5px',
      fontFamily: 'Inter',
      fontWeight: 500,
      fontSize: '14px',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
    },
    '& .MuiSnackbarContent-message': {
      padding: 0
    },
    '& .MuiSnackbarContent-action': {
      padding: 0
    }
  }
};
