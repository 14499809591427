import React from 'react';
import { useForm } from 'react-hook-form';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { Box, Grid, IconButton, makeStyles, Modal } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { Button, Tooltip, Typography } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import dayjs from 'dayjs';
import accountProductsApi from '../../apis/swagup/accountProducts';
import apiPaths from '../../helpers/apiPaths';
import { isPack } from '../../helpers/utils';
import { StandardCard } from '../shared/containers/Cards';
import { ImgWithHandler } from '../global/ImgUtils';
import { ProductColorV2, useReorderLink } from './InventoryCommon';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import { reorderProductDisabledCopy } from '../../helpers/helperConstants';
import IntegrationPanel from '../integrationsDev/IntegrationPanel';
import ArrowTooltip from '../pages/orders/requested/common/Tooltip';
import { useMembership } from '../../contexts/membershipContext';
import { useCompany } from '../../hooks';

import { PRODUCT_DUE_DATE_STATUSES } from '../../utils/constants';
import USShippingOnlyTag from '../global/USShippingOnlyTag';

const useProductImageStyles = makeStyles({
  container: {
    margin: '12px 0 8px 0px',
    width: '100%',
    height: 216,
    position: 'relative',
    '&:hover': {
      '& .zoom': {
        display: 'flex !important'
      }
    }
  },
  image: {
    height: '100%',
    width: '100%',
    objectFit: 'contain !important'
  },
  link: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    visibility: 'hidden',
    opacity: 0,
    transition: 'all 250ms ease',
    'div:hover > &, a:focus &': {
      visibility: 'visible',
      opacity: 1
    }
  },
  productZoomImage: {
    marginTop: 4,
    width: 28,
    height: 28
  }
});

const ProductImage = ({ item }) => {
  const classes = useProductImageStyles();
  const location = useLocation();
  const linkRef = React.useRef();

  const urlParams = new URLSearchParams(location.search);
  urlParams.set('details', item.id);

  return (
    <div className={classes.container}>
      <ImgWithHandler src={item.image} width={256} alt={item.name} height={256} className={classes.image} />
      <Link to={{ ...location, search: urlParams.toString() }} ref={linkRef} onClick={() => linkRef.current.blur()}>
        <Grid
          id="zoom-container"
          container
          justifyContent="center"
          alignItems="center"
          className="zoom"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            display: 'none',
            background: 'rgba(255, 255, 255, 0.5)'
          }}
        >
          <Grid id="zoom-wrapper" item>
            <img id="zoom-image" src="/images/redeem/zoom-in.svg" className={classes.productZoomImage} alt="zoom" />
          </Grid>
        </Grid>
      </Link>
    </div>
  );
};

const useProductNameStyles = makeStyles({
  container: ({ isValid }) => ({
    padding: '0 6px',
    margin: '0 -6px',
    borderRadius: 4,
    transition: 'box-shadow 250ms ease',
    boxShadow: isValid ? '' : '0 0 1px 1px #F44336',
    '&:hover, &:focus-within': {
      boxShadow: isValid ? '0 0 1px 1px #D6D8DB' : '0 0 1px 1px #F44336'
    }
  }),
  input: {
    // reset styles
    flex: 1,
    width: '100%',
    border: 'none',
    outline: 'none',
    padding: 0,
    // font styles
    fontFamily: 'Inter',
    fontWeight: 700,
    fontSize: 20,
    lineHeight: '28px',
    letterSpacing: '-0.03em',
    color: '#131415',
    ...ellipsisStyles
  },
  inputIcon: {
    fontSize: 12,
    color: '#131415',
    marginRight: '-6px',
    visibility: 'hidden',
    opacity: 0,
    transition: 'opacity 250ms ease',
    'div:hover > input:not(:focus) + &': {
      marginRight: '0',
      visibility: 'visible',
      opacity: 1
    }
  }
});

const ProductName = ({ item }) => {
  const { formState, register, reset, handleSubmit, getValues, setValue } = useForm({
    mode: 'all',
    defaultValues: { name: item.name }
  });
  const { errors } = formState;
  const isValid = !errors?.name;

  const classes = useProductNameStyles({ isValid });

  const { ref, ...registerProps } = register('name', {
    required: true,
    maxLength: 80,
    validate: value => value.trim() !== '' || 'field required'
  });

  const inputRef = React.useRef();
  const setInputRef = r => {
    inputRef.current = r;
    ref(r);
  };

  const queryClient = useQueryClient();
  const { mutate: editName, isLoading } = useMutation(
    [apiPaths.accountProducts, { ids: item.id }],
    name => accountProductsApi.editName({ prodId: item.id, name }),
    {
      onSuccess: ({ name }) => {
        reset({ name });
        queryClient.invalidateQueries([apiPaths.accountProducts]);
      }
    }
  );

  const onSubmit = data => {
    if (data.name === item.name) return;

    editName(data.name);
  };

  const handleOnKeyDown = e => {
    if ([27, 13].includes(e.keyCode)) {
      if (e.keyCode === 27) {
        reset();
      }
      inputRef.current.blur(e);
    }
  };

  const handleOnBlur = () => {
    if (!isValid) {
      reset();
    } else {
      const name = getValues('name').trim();
      setValue('name', name);
      onSubmit({ name });
    }
  };

  const handleOnIconClick = () => inputRef.current.focus();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container alignItems="center" className={classes.container}>
        <input
          {...registerProps}
          ref={setInputRef}
          onBlur={handleOnBlur}
          onKeyDown={handleOnKeyDown}
          className={classes.input}
          maxLength={80}
          disabled={isLoading}
        />
        <EditIcon className={classes.inputIcon} onClick={handleOnIconClick} />
      </Grid>
    </form>
  );
};

const ProductQuantities = ({ item }) => (
  <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 1fr)' }}>
    {item.stock.map(qps => (
      <Typography
        key={qps.size.id}
        variant="body3RegularInter"
        style={{
          marginTop: 8,
          color: qps.quantity === 0 ? '#989EA4' : '#4A4F54',
          gridColumn: qps.size.name === 'One Size' ? '1/3' : ''
        }}
      >
        {qps.size.name}: {qps.quantity}
      </Typography>
    ))}
  </div>
);

const useStyles = makeStyles({
  card: {
    padding: '20px 20px 24px 20px',
    borderRadius: 12
  },
  reorderLink: {
    marginTop: 16,
    height: 'unset',
    padding: 0,
    '&:hover': {
      textDecoration: 'underline !important'
    }
  },
  tooltip: {
    fontSize: 14,
    textAlign: 'center',
    maxWidth: 350
  },
  inventoryTimer: {
    display: 'flex',
    alignItems: 'center',
    gap: 5
  },
  inventoryModalDetail: {
    backgroundColor: 'white',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    maxWidth: '95%',
    borderRadius: 10,
    maxHeight: '90% !important',
    overflowY: 'hidden'
  }
});

const InventoryTimerIcon = ({ onClick, dueStatus }) => {
  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const FreeInventoryStorageFee = storageDiscount === Number(100);

  return (
    <IconButton onClick={onClick} style={{ height: 24, width: 24 }}>
      <AccessTimeIcon
        style={{
          height: 20,
          width: 20,
          color: dueStatus === PRODUCT_DUE_DATE_STATUSES.normal || FreeInventoryStorageFee ? '#125CFF' : '#C62828'
        }}
      />
    </IconButton>
  );
};

const InventoryCardItemNew = ({ item, clearInventoryHistory }) => {
  const classes = useStyles();
  const location = useLocation();
  const { storageFeeInventory } = useFlags();
  const history = useHistory();

  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();
  const FreeInventoryStorageFee = storageDiscount === Number(100);

  const handleIconClick = () => {
    clearInventoryHistory();
    const pathname = `/inventory/inventory-histories/${item?.id}`;
    history.push({
      pathname,
      state: { item }
    });
  };

  const type = isPack(item.record_type) ? 'PACK' : 'PRODUCT';

  const reorderLink = useReorderLink([item.id]);

  const hasStock = item?.stock.reduce((acc, size) => acc + size.quantity, 0) > 0;

  const currnetDate = dayjs().format('MM/DD/YYYY');
  const nextPaymentDate = dayjs(item?.nextPaymentDate, 'MM/DD/YYYY');
  const disableInventoryCTA =
    storageFeeInventory &&
    item.inventoryDueStatus !== PRODUCT_DUE_DATE_STATUSES.normal &&
    nextPaymentDate.diff(currnetDate, 'day') < 0;

  const { data: company } = useCompany();
  const negativeBalance = Number(company.balance) < 0;
  const allowNegativeBalance = company?.allow_negative_credit_balance;
  const noSendSwag = negativeBalance && !allowNegativeBalance;

  return (
    <>
      <StandardCard className={classes.card}>
        <Grid container alignItems="center">
          <Grid item xs className={classes.inventoryTimer}>
            <Typography variant="overlineMediumInter" style={{ color: '#B7BBBF' }}>
              {type}
            </Typography>
            {storageFeeInventory && item?.history.length !== 0 && (
              <InventoryTimerIcon onClick={handleIconClick} dueStatus={item.inventoryDueStatus} />
            )}
          </Grid>
          <Grid item>
            <IntegrationPanel originalProduct={item} />
          </Grid>
        </Grid>
        <ProductImage item={item} />
        <div style={{ marginBottom: 8 }}>
          <ProductName item={item} />
        </div>
        <ProductColorV2 item={item} />
        <div style={{ height: 84, marginBottom: 8 }}>
          <ProductQuantities item={item} />
        </div>
        <USShippingOnlyTag product={item} style={{ marginBottom: 6 }} />
        <Grid container direction="column" alignItems="center">
          <ArrowTooltip
            variant="alert"
            title={
              (!FreeInventoryStorageFee || FreeInventoryStorageFee) &&
              (disableInventoryCTA || noSendSwag) &&
              'Your Swag Card Balance has a Negative Balance. Please add funds to your Swag Card Balance to resume shipments'
            }
            style={{ width: '100%' }}
            placement="top-center"
            arrow
          >
            <Button
              variant="primary"
              component={Link}
              to={{
                pathname: '/send-swag/select-recipients',
                search: `product=${item.id}`,
                state: { from: location, item }
              }}
              disabled={!hasStock || noSendSwag || (!FreeInventoryStorageFee && disableInventoryCTA)}
              style={{ height: 40, width: '100%' }}
              fullWidth
            >
              Send Swag
            </Button>
          </ArrowTooltip>
          <Tooltip title={item.enabled ? '' : reorderProductDisabledCopy} classes={{ tooltip: classes.tooltip }}>
            <div>
              <Button
                component={Link}
                to={reorderLink}
                disabled={!item.enabled}
                className={classes.reorderLink}
                variant="text"
              >
                Reorder
              </Button>
            </div>
          </Tooltip>
        </Grid>
      </StandardCard>
    </>
  );
};

export default InventoryCardItemNew;
