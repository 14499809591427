/* eslint-disable no-nested-ternary */
import * as React from 'react';
import {
  Checkbox,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  IconButton,
  Tooltip,
  makeStyles
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import Warning from '@material-ui/icons/Warning';
import Scrollbars from 'react-scrollbars-custom';
import clsx from 'clsx';
import debounce from 'lodash/debounce';
import join from 'lodash/join';

import { Button } from '@swagup-com/components';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import InventoryItemDetailsModal from '../../../../inventory/InventoryItemDetailsModal';
import { useQueryParams, useSelectedProducts } from '../../../../../hooks';
import { Img } from '../../../../global/ImgUtils';
import Loader from '../../../../global/Loader';
import CircularCheckbox from '../common/CircularCheckbox';
import { cleanShipmentGroup, selectShipmentGroupProducts } from '../../../../../actions/shipmentGroupActions';
import { isOneSize, sumByQuantity } from '../../../../../helpers/utils';
import { productImageBasedOnStatus } from '../../../../global/proofsCommon';
import { FilledSearchField, MultiselectFilter } from '../../../../global/Filters';
import { Pagination, EmptyState } from '../../../../shared';
import { useInSendSwagPath } from '../hooks';
import ArrowTooltip from '../common/Tooltip';
import styles from './SelectProducts.styles';
import { mapProductToProof } from '../common/utilsOrder';
import useInventoryHistory from '../../../../../hooks/useInventoryHistory';
import { useMembership } from '../../../../../contexts/membershipContext';
import USShippingOnlyTag from '../../../../global/USShippingOnlyTag';

const notEnoughStockMsg = "This product doesn't have enough inventory to cover the selected recipients";

const useStyles = makeStyles(styles);

const SizesBreakdown = ({ sizes, onClickAway }) => {
  const classes = useStyles();

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Grid container onClick={e => e.preventDefault()}>
        {sizes.map(size => (
          <Grid
            key={size.size.id}
            item
            xs={4}
            className={clsx(classes.size, { [classes.unavailableSize]: size.quantity === 0 })}
          >
            {size.size.name}: {size.quantity}
          </Grid>
        ))}
      </Grid>
    </ClickAwayListener>
  );
};

const dueDateStatuses = {
  normal: 'normal',
  warning: 'warning',
  alert: 'alert'
};

const ProductItem = ({ product, isSelected, minQuantityNeeded, onCheck, preventFurtherActions = false }) => {
  const isStockLimited = useInSendSwagPath();
  const [expanded, setExpanded] = React.useState(false);
  const [viewedProduct, setViewedProduct] = React.useState();
  const handleExpandClick = () => setExpanded(prev => !prev);

  const disableInventoryCTA = !!product?.disableInventoryCTA;

  const totalQty = sumByQuantity(product.sizes);
  const unavailable = totalQty === 0 || minQuantityNeeded > totalQty;
  const classes = useStyles({ unavailable });

  return (
    <Grid container alignItems="center">
      <Grid item>
        <ArrowTooltip
          variant="alert"
          title={
            disableInventoryCTA && preventFurtherActions
              ? 'Your Swag Card Balance has a Negative Balance. Please add funds to your Swag Card Balance to resume shipments'
              : disableInventoryCTA || preventFurtherActions
              ? 'Complete the Payment to enable the options'
              : ''
          }
          style={{ width: '100%' }}
          placement="top-center"
          arrow
        >
          <CircularCheckbox
            disabled={unavailable || disableInventoryCTA}
            className={classes.checkbox}
            checked={isSelected}
            onChange={onCheck}
            inputProps={{ 'aria-label': product.product.name, 'aria-checked': isSelected }}
          />
        </ArrowTooltip>
      </Grid>
      <div className={classes.productImageContainer}>
        <Img
          src={productImageBasedOnStatus(product.product, 78, 78)}
          alt={product.product.name}
          className={classes.image}
          style={{ objectFit: 'contain' }}
        />
        <Grid
          id="zoom-container"
          container
          justifyContent="center"
          alignItems="center"
          className="zoom"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            height: '100%',
            display: 'none',
            background: 'rgba(255, 255, 255, 0.5)'
          }}
          onClick={({ target: { id } }) => (id?.includes('zoom-') ? setViewedProduct(product.product) : false)}
        >
          <Grid id="zoom-wrapper" item>
            <img id="zoom-image" src="/images/redeem/zoom-in.svg" className={classes.productZoomImage} alt="zoom" />
          </Grid>
        </Grid>
      </div>
      <Grid item container direction="column" justifyContent="center" style={{ flex: 1, minWidth: 0, paddingLeft: 24 }}>
        <Grid container alignItems="center">
          <Grid item xs>
            <p className={classes.name}>{product.product.name}</p>
            {isStockLimited && (
              <Grid container alignItems="center" className={classes.quantity}>
                <span>Available quantity: {totalQty}</span>
                {totalQty > 0 && (
                  <Grid item container xs justifyContent="flex-start">
                    {isOneSize(product.sizes) ? (
                      <span style={{ marginLeft: 4 }}>(One size)</span>
                    ) : (
                      <Tooltip
                        open={expanded}
                        title={<SizesBreakdown sizes={product.sizes} onClickAway={() => setExpanded(false)} />}
                        placement="bottom"
                        interactive
                        classes={{ tooltip: classes.sizesBreakdown }}
                      >
                        <IconButton
                          style={{ padding: 5, marginLeft: 5, marginTop: -2 }}
                          onClick={!expanded ? handleExpandClick : undefined}
                        >
                          {expanded ? <ExpandLessIcon fontSize="small" /> : <ExpandMoreIcon fontSize="small" />}
                        </IconButton>
                      </Tooltip>
                    )}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
          <Grid item>
            <USShippingOnlyTag product={product.product} style={{ marginRight: 24 }} />
          </Grid>
        </Grid>
      </Grid>
      {isStockLimited && minQuantityNeeded > totalQty && (
        <ArrowTooltip title={notEnoughStockMsg}>
          <Warning style={{ fontSize: 16, color: '#f44336' }} />
        </ArrowTooltip>
      )}
      <InventoryItemDetailsModal item={viewedProduct} setViewedProduct={setViewedProduct} hidePDFDownload />
    </Grid>
  );
};

const filterOptions = [
  { value: 'product', text: 'Products' },
  { value: 'pack', text: 'Packs' }
];

const getFilter = (value, selectedOptions) =>
  filterOptions.find(option => option.value === value && selectedOptions.find(selected => selected === option.text));

const SelectProducts = ({ isFetching, products, pagination, preventFurtherActions = false }) => {
  const classes = useStyles({ preventFurtherActions });
  const history = useHistory();
  const location = useLocation();

  const dispatch = useDispatch();

  const {
    currentMembership: { storage_discount: storageDiscount }
  } = useMembership();

  const filteredProducts = React.useMemo(() => {
    const { storageFeeInventory } = useFlags();

    return products.map(product => {
      const nextPaymentDate = dayjs(product?.nextPaymentDate, 'MM/DD/YYYY');
      const currentDate = dayjs().format('MM/DD/YYYY');

      const FreeInventoryStorageFee = storageDiscount === Number(100);

      const disableInventoryCTA =
        preventFurtherActions ||
        (!FreeInventoryStorageFee &&
          storageFeeInventory &&
          product.inventoryDueStatus !== dueDateStatuses.normal &&
          nextPaymentDate.diff(currentDate, 'day') < 0);
      return { ...product, disableInventoryCTA };
    });
  }, [products, preventFurtherActions, storageDiscount]);

  const preselectedRecipients = location.state?.contacts?.length ?? 0;
  const isProductAvailable = React.useCallback(
    product => sumByQuantity(product.sizes) >= (preselectedRecipients || 1) && product.disableInventoryCTA === false,
    [preselectedRecipients]
  );
  const { products: previouslySelected } = useSelector(state => state.shipmentGroup);
  const { selectedProducts, isLoading, checkboxProps, handleCheck, handleCheckAll } = useSelectedProducts({
    products: filteredProducts,
    previouslySelected,
    isProductAvailable,
    mapResults: mapProductToProof
  });

  const query = useQueryParams();
  const search = query.get('search') || '';
  const [searchValue, setSearchValue] = React.useState(search);

  React.useEffect(() => {
    if (query.get('selected') === '') {
      query.delete('selected');
      history.replace({ ...history.location, search: query.toString() });
    }
  }, [query, history]);

  const navigateToFirstPage = () => {
    query.set('page', 1);
    history.replace({ ...location, search: query.toString() });
  };

  const debouncedSearch = React.useCallback(
    debounce(searchText => {
      if (searchText) query.set('search', searchText);
      else query.delete('search');
      navigateToFirstPage();
    }, 750),
    [search, query]
  );

  const handleSearchOption = e => {
    const { value } = e.target;
    setSearchValue(value);
    debouncedSearch(value);
  };

  const productType = query.get('product_type') || '';

  const handleProductTypeChange = e => {
    const filters = e.target.value.split(',');

    query.delete('product_type');
    const bulkFilter = getFilter('product', filters);
    const packsFilter = getFilter('pack', filters);
    if (bulkFilter || packsFilter) {
      query.set('product_type', join([bulkFilter?.value, packsFilter?.value].filter(Boolean)));
    }

    navigateToFirstPage();
  };

  const handleContinue = () => {
    dispatch(cleanShipmentGroup);
    dispatch(selectShipmentGroupProducts([...selectedProducts.values()]));

    history.push({ pathname: 'select-recipients', state: location.state });
  };

  const selectedValues = join(
    productType
      .split(',')
      .map(value => filterOptions.find(option => option.value === value)?.text)
      .filter(Boolean)
  );

  const hasFilter = search !== '' || productType !== '';

  const { leftBarNavigation } = useFlags();

  return (
    <Grid container direction="column" className={`${classes.root} ${leftBarNavigation ? classes.navbarStyle : {}}`}>
      {isLoading && <Loader absolute />}
      <Grid container justifyContent="space-between">
        <Grid container>
          <Grid item>
            <h1 className={classes.title}>Select Products</h1>
            <p className={classes.subtitle}>Choose products you want to ship.</p>
          </Grid>
          {pagination && (
            <>
              <Grid item xs container justifyContent="center" alignItems="center">
                <Grid style={{ width: 365 }}>
                  <FilledSearchField
                    value={searchValue}
                    placeholder="Search"
                    onChange={handleSearchOption}
                    height={56}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex', alignItems: 'center' }}>
                <MultiselectFilter
                  height={56}
                  width={235}
                  values={filterOptions}
                  selected={selectedValues}
                  onApply={handleProductTypeChange}
                  title="Filter by:"
                  defaultText="In Stock"
                  behavior="radiobutton"
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.allCheckboxsContainer}>
        <FormControlLabel
          className={classes.formControl}
          control={
            <Checkbox
              disableRipple
              disableFocusRipple
              disableTouchRipple
              className={classes.allCheckbox}
              inputProps={{ 'aria-label': `Selection shortcut actions checkbox` }}
              onChange={e => (preventFurtherActions ? {} : handleCheckAll(e))}
              {...checkboxProps}
            />
          }
          label={<p className={classes.demoLabel}>{checkboxProps.checked ? 'Deselect all' : 'Select all'}</p>}
        />
      </Grid>

      {products.length === 0 &&
        (hasFilter ? (
          <EmptyState
            title="No products found"
            image={{
              path: '/images/orders/empty-filtered-inventory.webp',
              alt: 'No products found'
            }}
          />
        ) : (
          <EmptyState
            title="You haven't ordered any products yet!"
            image={{
              path: '/images/orders/empty-inventory.jpg',
              alt: 'No inventory',
              text: 'To send swag, start a new order or check the status of your pending orders.'
            }}
            button={{
              link: '/product-onboarding',
              text: 'Start new order'
            }}
          />
        ))}

      {isFetching ? (
        <Grid item xs container justifyContent="center" alignItems="center">
          <CircularProgress size={80} />
        </Grid>
      ) : (
        <Scrollbars className={classes.products}>
          {filteredProducts.map((product, index) => (
            <div key={product.id}>
              <ProductItem
                key={product.id}
                product={product}
                isSelected={selectedProducts.has(product.id)}
                onCheck={() => handleCheck(product)}
                minQuantityNeeded={preselectedRecipients}
                preventFurtherActions={preventFurtherActions}
              />
              {index < products.length - 2 && <div className={classes.separator} />}
            </div>
          ))}
        </Scrollbars>
      )}
      {products.length > 0 && (
        <Grid
          container
          justifyContent={pagination ? 'space-between' : 'flex-end'}
          className={clsx(classes.btnContainer, { show: selectedProducts.size || pagination })}
        >
          {pagination && <Pagination {...pagination} endText="products" />}
          <Button
            variant="primary"
            className={classes.btn}
            onClick={handleContinue}
            disabled={!selectedProducts.size || preventFurtherActions}
          >
            Select Recipients <ChevronRightIcon className={classes.btnIcon} />
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default SelectProducts;
