import * as React from 'react';
import { Box, Grid, Link } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Typography } from '@swagup-com/components';
import { LocalShippingOutlined } from '@material-ui/icons';
import { useOrder } from '../../OrderContext';
import { ProductProvider } from '../../common/ProductContext';

import { Header, OrderProduct } from './common';

const WarningBox = ({ names }) => {
  return (
    <Grid
      container
      alignItems="center"
      spacing={3}
      style={{ padding: '6px 24px', background: '#FFF3E0', marginBottom: 8 }}
    >
      <Grid item>
        <LocalShippingOutlined style={{ color: '#131415', marginBottom: -5 }} />
      </Grid>
      <Grid item xs>
        <Typography variant="body3RegularInter" style={{ color: '#131415' }}>
          The product{`${names.length > 1 ? 's' : ''}`} "{names.join('", "')}" in this order has US only shipping
          restrictions.
        </Typography>
      </Grid>
      <Grid item>
        <Link
          href="https://support.swagup.com/en/articles/6952397-international-shipments-restricted-items"
          target="_blank"
          style={{ fontSize: 14, fontFamily: 'Inter' }}
        >
          Learn more
        </Link>
      </Grid>
    </Grid>
  );
};

const ReviewOrder = () => {
  const order = useOrder();
  const products = order?.products ?? [];

  const usOnlyShippingProducts = products.map(p => p.product).filter(p => !p.can_ship_international);

  console.log('xxx: ', products, usOnlyShippingProducts);
  const hasWarning = !isEmpty(usOnlyShippingProducts);

  return (
    <Box>
      <Header header="Review your order" />
      {hasWarning && <WarningBox names={usOnlyShippingProducts.map(p => p.name)} />}
      <Box>
        {products.map(product => (
          <ProductProvider key={product.id} product={product}>
            <OrderProduct />
          </ProductProvider>
        ))}
      </Box>
    </Box>
  );
};

export default ReviewOrder;
