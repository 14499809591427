import { Grid, makeStyles } from '@material-ui/core';
import { Tabs } from '@swagup-com/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import * as React from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { STATUS_CHANGED } from '../../actions/types';
import tags from '../../apis/seoTags';
import accountProductsApi from '../../apis/swagup/accountProducts';
import { useMembership } from '../../contexts/membershipContext';
import apiPaths from '../../helpers/apiPaths';
import { joinFields, generateFilterOptions } from '../../helpers/utils';
import { usePaginatedQuery, usePerPageOptions, useProfile, useQueryParams, useSelectedProducts } from '../../hooks';
import useDiscountPricing from '../../hooks/useDiscountPricing';
import { useProductsFilters, useQueryFilterValidated } from '../../hooks/useFilters';
import useIntegrations from '../../hooks/useIntegrations';
import { statuses } from '../../reducers/globalNotificationReducer';
import Loader from '../global/Loader';
import InventoryItemDetailsModal from '../inventory/InventoryItemDetailsModal';
import { CenteredGrid, EmptyState, Helmet, Pagination } from '../shared';
import SearchSortFilter from '../shared/SearchSortFilter';
import { CardsContainer } from '../shared/containers/Cards';
import { ActionBar, CustomTab, DeleteModal, ProductCard, ProductsHeader } from './commonProductsElements';
import styles from './styles/ProductsHome';
import { PRODUCT_STATUS_OPTIONS } from '../../utils/constants';
import global from '../../apis/swagup/global';

const useStyles = makeStyles(styles);

const ProductsHome = ({ isInsideDrawer, closeDrawer, excludedProducts = [] }) => {
  const recordType = useQueryFilterValidated(
    'record_type',
    (id, value) => ['product', 'pack'].includes(id) && value.split(',').length === 1
  );
  const category = useQueryFilterValidated('category');
  const ordering = useQueryFilterValidated(
    'ordering',
    (id, value) => ['-created_at', 'created_at', 'name', '-name'].includes(id) && value.split(',').length === 1,
    false,
    '-created_at'
  );

  const classes = useStyles();
  const history = useHistory();
  const query = useQueryParams();
  const dispatch = useDispatch();
  const { path } = useRouteMatch();
  const queryClient = useQueryClient();
  const { data: profile } = useProfile();
  const perPageOptions = usePerPageOptions();
  const {
    showDesignTabFeTemp102521: showProductsTab,
    swagupMembershipPlans,
    leftBarNavigation,
    mockUpApproval
  } = useFlags();

  const search = query.get('search') || '';
  const status = query.get('status') || '';
  const new_status = query.get('new_status') || '';
  const can_ship_international = useQueryFilterValidated('can_ship_international', id =>
    ['true', 'false'].includes(id)
  );

  const haveActiveFilters = [search, recordType, status, category, ordering, can_ship_international].some(
    e => e.length > 0
  );

  const [state, setState] = React.useState({ productCount: -1, haveActiveFilters });
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [filters, setFilters] = React.useState([]);

  React.useEffect(() => {
    global
      .fetchFilters('products')
      .then(data => {
        setFilters(data);
      })
      .catch(error => {
        console.error('Error fetching filters:', error);
      });
  }, []);

  const accountProductsParams = {
    search,
    record_type: recordType,
    status,
    new_status,
    category,
    ordering,
    products: 'not_soft_deleted',
    can_ship_international
  };

  if (!new_status) {
    accountProductsParams.new_status = 'all';
  }

  const { query: queryResult, queryKey, pagination } = usePaginatedQuery({
    queryKey: [apiPaths.accountProducts, accountProductsParams],
    queryFn: (limit, offset) => accountProductsApi.fetch({ limit, offset, ...accountProductsParams }),
    perPageOptions
  });

  const { data: productFiltersData = {}, isLoading: productFiltersLoading } = useProductsFilters();

  const { data, isPreviousData, isFetching } = queryResult;

  const { getProductsWithDiscount } = useDiscountPricing();
  const [products, productCount] = data
    ? [getProductsWithDiscount(data.results), data.count]
    : [[], state.productCount];
  const [viewedProduct, setViewedProduct] = React.useState();
  const { selectedProducts, handleCheck, clearSelection, isLoading } = useSelectedProducts({
    products
  });
  const totalSelected = selectedProducts.size;
  const selectedMsg = totalSelected === 1 ? '' : 's';

  const dispatchSuccess = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.success, message } });
  const dispatchError = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.error, message } });

  const { mutate: deleteProducts, isLoading: isDeletingProducts } = useMutation(
    accountProductsApi.deleteAccountProducts,
    {
      onSuccess: () => {
        dispatchSuccess(`We successfully deleted ${totalSelected} product${selectedMsg}`);
        queryClient.invalidateQueries(apiPaths.accountProducts);
        clearSelection();
      },
      onError: () => dispatchError(`Product${selectedMsg} deletion failed`)
    }
  );

  React.useEffect(() => {
    if (productCount < 0 || isPreviousData) return;

    setState({ productCount, haveActiveFilters });
  }, [productCount, haveActiveFilters, isPreviousData]);

  React.useEffect(() => {
    queryClient.invalidateQueries(apiPaths.accountProducts);
  }, [queryClient]);

  React.useEffect(() => {
    if (window.delighted)
      window.delighted.survey({
        email: profile?.email,
        name: joinFields([profile.first_name, profile.last_name], ' ')
      });
  }, [profile]);

  const handleEditNameSuccess = product =>
    queryClient.setQueryData(queryKey, accountProducts => ({
      ...accountProducts,
      results: accountProducts.results.map(p => (p.id === product.id ? { ...p, name: product.name } : p))
    }));

  if (!showProductsTab) return <Redirect to="/" />;

  if (state.productCount === -1 || isLoading) return <Loader />;

  const [link, text] = state.haveActiveFilters
    ? [path, 'Remove filters']
    : [{ pathname: '/product-onboarding', state: { fromProducts: true } }, 'Start a new design'];

  const handleOnRecordTypeChange = value => {
    if (!value) query.delete('record_type');
    else query.set('record_type', value);

    history.replace({ ...history.location, search: query.toString() });
  };

  const handleOnDelete = () => {
    if (totalSelected === 0) return;
    dispatch({
      type: STATUS_CHANGED,
      payload: {
        status: statuses.pending,
        message: `Deleting ${totalSelected} selected product${selectedMsg}`
      }
    });
    setOpenDeleteModal(false);
    deleteProducts([...selectedProducts.keys()]);
  };
  const {
    currentMembership: { isFreeTier, product_discount }
  } = useMembership();

  const { product_statuses: productStatuses, product_categories: productCategories } = productFiltersData;
  const statusOptionsV1 = productFiltersLoading
    ? {}
    : Object.fromEntries(productStatuses.slice().map(ps => [ps.id, ps.name]));

  const categoryOptions = productFiltersLoading
    ? {}
    : Object.fromEntries(productCategories.slice().map(ps => [ps.id, ps.name]));

  const shippingOptions = { false: 'US only', true: 'Global shipping' };

  const statusOptions = mockUpApproval ? generateFilterOptions(filters?.statuses) : statusOptionsV1;
  const searchSortFilterConfig = {
    search: { placeholder: 'Search Products' },
    filters: [
      { label: 'Status', queryParam: mockUpApproval ? 'new_status' : 'status', options: statusOptions },
      { label: 'Category', queryParam: 'category', options: categoryOptions },
      { label: 'Shipping', queryParam: 'can_ship_international', options: shippingOptions, isSingleSelect: true }
    ]
  };
  return (
    <>
      <Helmet tags={tags.products} />
      <CenteredGrid container justifyContent="center">
        <Grid container>
          <Grid item xs className={classes.container}>
            {!leftBarNavigation && <ProductsHeader isInsideDrawer />}
            {leftBarNavigation && isInsideDrawer && <ProductsHeader isInsideDrawer />}
            <Tabs value={recordType} onChange={(e, newValue) => handleOnRecordTypeChange(newValue)}>
              <CustomTab label="All" value="" />
              <CustomTab label="Packs" value="pack" />
              <CustomTab label="Products" value="product" />
            </Tabs>
            <Grid container alignItems="center">
              <SearchSortFilter config={searchSortFilterConfig} />
            </Grid>
            <Grid container justifyContent="center" style={{ position: 'relative' }}>
              {isFetching && <Loader absolute />}
              {pagination.count === 0 ? (
                <EmptyState
                  title="No Product Found"
                  image={{
                    path: '/images/products/product-tshirt.png',
                    alt: 'No Design Found',
                    text: state.haveActiveFilters
                      ? 'Remove filters to see all the products.'
                      : 'Check out the catalog to get started'
                  }}
                  button={{ link, text }}
                />
              ) : (
                <>
                  <CardsContainer className={classes.cardsContainer}>
                    {products.map(product => (
                      <ProductCard
                        isSelected={excludedProducts.includes(product.name)}
                        key={product.id}
                        product={product}
                        selected={selectedProducts.has(product.id)}
                        onSelect={() => handleCheck(product)}
                        onEditNameSuccess={handleEditNameSuccess}
                        setViewedProduct={setViewedProduct}
                        discount={product_discount}
                        showDiscount={swagupMembershipPlans && !isFreeTier}
                      />
                    ))}
                  </CardsContainer>
                  <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    className={
                      totalSelected > 0 && !isDeletingProducts ? classes.actionBarOpen : classes.actionBarClose
                    }
                  >
                    <Pagination {...pagination} endText="products" />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <ActionBar
            selectedProducts={selectedProducts}
            openDeleteModal={openDeleteModal}
            isDeletingProducts={isDeletingProducts}
            onClear={clearSelection}
            closeDrawer={closeDrawer}
            setOpenDeleteModal={setOpenDeleteModal}
            isInsideDrawer={isInsideDrawer}
          />
          <DeleteModal
            open={openDeleteModal}
            totalSelected={totalSelected}
            onDelete={handleOnDelete}
            onClose={() => setOpenDeleteModal(false)}
          />
        </Grid>
        <InventoryItemDetailsModal item={viewedProduct} setViewedProduct={setViewedProduct} hidePDFDownload />
      </CenteredGrid>
    </>
  );
};

export default ProductsHome;
