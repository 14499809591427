import React, { useRef } from 'react';
import {
  Grid,
  makeStyles,
  Slide,
  IconButton,
  ButtonBase,
  Snackbar,
  Tooltip as MuiTooltip,
  withStyles,
  MenuItem,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
  OutlinedInput,
  Dialog,
  DialogTitle,
  DialogContent
} from '@material-ui/core';
import { Button, Typography, Tab } from '@swagup-com/components';
import {
  ChevronRight,
  InfoOutlined,
  Search as SearchIcon,
  DeleteOutline,
  Delete,
  Close,
  Cancel,
  ShoppingCartOutlined,
  ShoppingCart,
  CallMergeOutlined,
  CallMerge,
  Check
} from '@material-ui/icons';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import countBy from 'lodash/countBy';
import debounce from 'lodash/debounce';

import {
  productImageBasedOnStatus,
  proofStatusStyles,
  proofStatusStylesNew,
  StatusChip,
  textOnHoverNew
} from '../global/proofsCommon';
import { Img } from '../global/ImgUtils';
import EditField from '../global/EditField';
import { StandardCard } from '../shared/containers/Cards';
import { productionTime } from '../../apis/constants';
import accountProductsApi from '../../apis/swagup/accountProducts';
import { orderApi } from '../../apis/swagup';
import { getIconButton, isPack, moneyStr } from '../../helpers/utils';
import { useQueryParams } from '../../hooks';
import Tooltip from '../shared/Tooltip';
import gtm from '../../utils/gtm';
import { useProductsFilters } from '../../hooks/useFilters';
import styles from './styles/ProductsHome';
import { STATUS_CHANGED, SET_OPPORTUNITY } from '../../actions/types';
import { statuses } from '../../reducers/globalNotificationReducer';
import apiPaths from '../../helpers/apiPaths';
import { CenteredGrid } from '../shared';
import Dropdown from '../global/Dropdown';
import { ellipsisStyles } from '../shared/styles/commonStyles';
import { changeColorLightness } from '../shared/styles/utils';
import SelectFilter from '../global/SelectFilter';
import DiscountBagde from '../global/DiscountBadge';
import StrikeOutText from '../global/StrikeOutCost';
import useIntegrations from '../../hooks/useIntegrations';
import { PRODUCT_STATUS_OPTIONS } from '../../utils/constants';
import USShippingOnlyTag from '../global/USShippingOnlyTag';

const useStyles = makeStyles(styles);

export const CustomTab = withStyles({
  root: {
    minWidth: 30,
    padding: 0,
    margin: '0 16px',
    '&:first-of-type': {
      marginLeft: 0
    },
    '&:last-of-type': {
      marginRight: 0
    }
  }
})(Tab);

const CustomCheckbox = withStyles({
  root: {
    padding: 10,
    paddingLeft: 0,
    '& .MuiSvgIcon-root': {
      fontSize: 14
    }
  }
})(Checkbox);

const CustomTooltip = withStyles({
  tooltip: {
    backgroundColor: '#3B4048',
    maxWidth: 'unset',
    padding: 10,
    borderRadius: 4,
    '& > p': { color: '#FFF' }
  },
  arrow: {
    color: '#3B4048'
  }
})(({ children, title, ...props }) => (
  <MuiTooltip
    arrow
    title={title ? <Typography variant="body3RegularInter">{title}</Typography> : ''}
    placement="top"
    {...props}
  >
    <div>{children}</div>
  </MuiTooltip>
));

const ProductsHeader = () => {
  const classes = useStyles();
  const { leftBarNavigation } = useFlags();

  return (
    <Grid container alignItems="center" className={classes.headerContainer}>
      <Grid item xs>
        <Typography variant="h2BoldInter">My Products</Typography>
      </Grid>
      <Grid item>
        <Button
          variant="primary"
          component={Link}
          to={{ pathname: '/product-onboarding', state: { fromProducts: true } }}
          onClick={() => gtm.onClick('Design Products')}
          style={{ height: 56, background: leftBarNavigation ? '#131415' : '#3577D4' }}
        >
          Design Products
        </Button>
      </Grid>
    </Grid>
  );
};
const CustomCheckIcon = () => (
  <div style={{ height: 24, width: 24 }}>
    <Check style={{ color: '#3577d4', height: 18 }} />
  </div>
);

const defaultSortOptions = {
  '-created_at': 'Most Recent',
  created_at: 'Less Recent',
  name: 'A - Z',
  '-name': 'Z - A'
};

const SortBy = ({ sortOptions = defaultSortOptions, customTitle = 'Sort By' }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const location = useLocation();
  const query = useQueryParams();

  const selected = sortOptions[query.get('ordering')] || 'Price High to Low';

  const title = selected && (
    <Typography variant="body2RegularInter" className={classes.label}>
      {customTitle}: <span>{selected}</span>
    </Typography>
  );

  return (
    <Dropdown label={title} blank anchorEl={anchorEl} onClose={() => setAnchorEl()} setAnchorEl={setAnchorEl}>
      {Object.entries(sortOptions).map(([value, name]) => {
        const newQuery = new URLSearchParams(query);
        newQuery.set('ordering', value);
        return (
          <MenuItem
            component={Link}
            key={value}
            to={{ ...location, search: newQuery.toString() }}
            onClick={() => setAnchorEl()}
          >
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
                  {name}
                </Typography>
              </Grid>
              <Grid item>{selected === name && <CustomCheckIcon />}</Grid>
            </Grid>
          </MenuItem>
        );
      })}
    </Dropdown>
  );
};

const FilterBy = ({ label, options, initialValues, onApply, columns = 1, excluded, isApplyOnOutsideClick = false }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState();
  const [selected, setSelected] = React.useState(new Set(initialValues));

  const handleOnSelect = value =>
    setSelected(prev => {
      if (!prev.delete(value)) prev.add(value);
      return new Set(prev);
    });

  const handleOnKeyDown = value => e => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      handleOnSelect(value);
    }
  };

  const handleOnApply = () => {
    setAnchorEl(null);
    onApply(selected.size > 0 ? Array.from(selected) : null);
  };

  const handleOnClose = () => {
    setAnchorEl();
    if (isApplyOnOutsideClick) {
      handleOnApply();
    } else {
      setSelected(new Set(initialValues));
    }
  };

  const title = (
    <Typography variant="body2RegularInter" className={classes.label}>
      {label} {<span className={classes.labelCounter}>{`${selected.size === 0 ? 'All' : `(${selected.size})`}`}</span>}
    </Typography>
  );

  return (
    <Dropdown
      label={title}
      anchorEl={anchorEl}
      onClose={handleOnClose}
      setAnchorEl={setAnchorEl}
      blank
      MenuListProps={{
        style: { padding: '6px 16px' },
        className: columns > 1 ? classes.menuList : null
      }}
      hidden={excluded}
    >
      <MenuItem component={Typography} variant="body3RegularInter" className={classes.filterHeader} disabled>
        Filter by {label}
      </MenuItem>
      {Object.entries(options).map(([value, name]) => (
        <Grid
          item
          xs={12 / columns}
          key={value}
          component={MenuItem}
          onKeyDown={handleOnKeyDown(value)}
          style={{ padding: 0, paddingLeft: 11 }}
        >
          <FormControlLabel
            control={
              <CustomCheckbox checked={selected.has(value)} onChange={() => handleOnSelect(value)} color="primary" />
            }
            label={
              <Typography variant="body3RegularInter" style={{ color: '#4A4F54' }}>
                {name}
              </Typography>
            }
          />
        </Grid>
      ))}
      <Divider className={classes.divider} />
      <Grid container justifyContent="space-between" alignItems="center">
        <ButtonBase onClick={() => setSelected(new Set())} className={classes.clearButton}>
          Clear
        </ButtonBase>
        <>
          {!isApplyOnOutsideClick && (
            <ButtonBase onClick={handleOnApply} className={classes.applyButton}>
              Apply
            </ButtonBase>
          )}
        </>
      </Grid>
    </Dropdown>
  );
};

const useSearchFieldStyles = makeStyles({
  root: {
    margin: ({ lean }) => (lean ? 0 : '0 16px'),
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    width: ({ hasNoData }) => (hasNoData ? '100%' : 190),
    minWidth: 190,
    transition: 'width 250ms ease, background 250ms ease',
    '&.MuiOutlinedInput-root.Mui-focused': { width: '100%', background: '#FFFFFF' }
  },
  input: {
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    padding: '12px 20px 12px 20px',
    background: 'transparent',
    ...ellipsisStyles
  },
  notchedOutline: {
    borderRadius: ({ rounded }) => (rounded ? 22 : 12),
    transition: 'border-color 250ms ease',
    borderWidth: ({ hasNoData }) => (hasNoData ? 1 : undefined),
    borderColor: ({ hasNoData, inverseHover }) =>
      inverseHover ? 'transparent' : hasNoData ? '#D6D8DB' : 'transparent',
    '.MuiOutlinedInput-root:hover input:not(:focus) + &': {
      borderColor: ({ inverseHover }) => (inverseHover ? '#D6D8DB' : 'transparent')
    },
    '.MuiOutlinedInput-root.Mui-focused &': {
      borderWidth: 1,
      borderColor: '#D6D8DB'
    }
  }
});

const SearchField = ({ placeholder, defaultValue, onChange, rounded, lean, inverseHover }) => {
  const [showCancel, setShowCancel] = React.useState(false);
  const classes = useSearchFieldStyles({ hasNoData: defaultValue !== '', rounded, lean, inverseHover });
  const ref = useRef(null);
  const debouncedSearch = React.useCallback(
    debounce(value => onChange(value), 750),
    [onChange]
  );
  const clearInput = () => {
    // 👇️ reset input field's value
    ref.current.value = '';
  };
  const iconStyle = { color: '#4A4F54', width: 20, height: 20 };
  return (
    <OutlinedInput
      inputRef={ref}
      defaultValue={defaultValue}
      onChange={e => {
        debouncedSearch(e.target.value);
        setShowCancel(true);
      }}
      // style={{ backgroundColor: '#F5F5F6' }}
      placeholder={placeholder}
      startAdornment={<SearchIcon style={iconStyle} />}
      endAdornment={
        defaultValue || showCancel ? (
          <Cancel
            onClick={() => {
              debouncedSearch('');
              setShowCancel(false);
              clearInput();
            }}
            style={{ ...iconStyle, cursor: 'pointer' }}
          />
        ) : null
      }
      classes={classes}
    />
  );
};

const recordTypeOptions = { product: 'Products', pack: 'Packs' };
const stockAvailabilityOptions = { available: 'In Stock', out_of_stock: 'Out of Stock' };
const canShipInternationalOptions = { true: 'Global', false: 'U.S Only' };

const Filters = ({
  statusFilterFunc = () => true,
  fullFilter,
  fullWidth,
  exclude,
  includeIntegrations,
  integrationFilterFunc = i => i.active
}) => {
  const { data = {}, isLoading } = useProductsFilters();
  const { product_statuses: productStatuses, product_categories: productCategories } = data;
  const statusOptions = isLoading
    ? {}
    : Object.fromEntries(
        productStatuses
          .slice()
          .filter(statusFilterFunc)
          .map(ps => [ps.id, ps.name])
      );

  const categoryOptions = isLoading ? {} : Object.fromEntries(productCategories.slice().map(ps => [ps.id, ps.name]));

  const { integrations, isLoading: isLoadingIntegrations } = useIntegrations();
  const integrationOptions =
    isLoadingIntegrations && integrations.length > 0
      ? {}
      : Object.fromEntries(
          integrations
            .slice()
            .filter(integrationFilterFunc)
            .map(integration => [integration.rutter_id, integration.name])
        );

  const query = useQueryParams();
  const history = useHistory();
  const handleOnApply = React.useCallback(
    queryName => value => {
      if (!value) {
        query.delete(queryName);
      } else {
        query.set(queryName, value);
      }
      history.replace({ ...history.location, search: query.toString() });
    },
    [history, query]
  );

  const searchParam = query.get('search') || '';
  const statusParam = query.get('status');
  const categoryParam = query.get('category');
  const integrationParam = query.get('integration');
  const initialStatusValues = statusParam?.split(',').filter(Boolean);
  const initialCategoryValues = categoryParam?.split(',').filter(Boolean);
  const initialIntegrationValues = integrationParam?.split(',').filter(Boolean);

  return (
    <CenteredGrid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{ width: fullWidth ? '100%' : undefined }}
    >
      <Box display="flex">
        <Typography variant="body2MediumInter" style={{ color: '#4A4F54' }}>
          FILTER
        </Typography>
        <Divider orientation="vertical" style={{ margin: '0 24px' }} flexItem />
        <FilterBy
          key={`status=${statusParam}`}
          label="Status:"
          options={statusOptions}
          initialValues={initialStatusValues}
          onApply={handleOnApply('status')}
          excluded={exclude?.includes('Status')}
        />
        <FilterBy
          key={`cateory=${categoryParam}`}
          label="Category:"
          options={categoryOptions}
          initialValues={initialCategoryValues}
          onApply={handleOnApply('category')}
          columns={2}
          excluded={exclude?.includes('Category')}
        />
        {fullFilter && (
          <>
            <SelectFilter
              customTitle="Type"
              property="record_type"
              selectOptions={recordTypeOptions}
              excluded={exclude?.includes('Type')}
            />
            <SelectFilter
              customTitle="Stock Status"
              property="inventory"
              selectOptions={stockAvailabilityOptions}
              excluded={exclude?.includes('Stock Status')}
            />
            <SelectFilter
              customTitle="Shipping Options"
              property="can_ship_international"
              selectOptions={canShipInternationalOptions}
              excluded={exclude?.includes('Shipping Options')}
            />
          </>
        )}
        {includeIntegrations && (
          <FilterBy
            key={`integration=${integrationParam}`}
            label="Integration:"
            options={integrationOptions}
            initialValues={initialIntegrationValues}
            onApply={handleOnApply('integration')}
            excluded={exclude?.includes('Integration')}
          />
        )}
      </Box>
      <Box display="flex" justifyContent="flex-end" alignItems="center" flex={1}>
        <SearchField
          key={searchParam || 'search'}
          placeholder="Search Products"
          defaultValue={searchParam}
          onChange={handleOnApply('search')}
        />
        <SortBy />
      </Box>
    </CenteredGrid>
  );
};

const ProductCard = ({
  isSelected = false,
  product,
  selected,
  onSelect,
  onEditNameSuccess,
  setViewedProduct,
  discount,
  showDiscount
}) => {
  const classes = useStyles(selected || isSelected);

  const editNameMutation = useMutation(({ name }) => accountProductsApi.editName({ prodId: product.id, name }), {
    onSuccess: onEditNameSuccess
  });

  const [imgSrc, imgAlt] = getIconButton(selected || isSelected);

  const priceInfo = (
    <p className={classes.priceInfo}>
      Estimated price based on current design and a 100-unit order. Learn more about our pricing{' '}
      <a href="/pricing" target="_blank">
        <span className={classes.priceInfoLink}>here</span>
      </a>
    </p>
  );

  const hasEmptyStock = product.stock?.length === 0 ?? true;

  return (
    <StandardCard className={classes.card} data-testid={`card-${product.id}`}>
      <Grid container>
        <Grid item xs={12} container alignItems="center" className={classes.cardHeader}>
          <Grid item xs>
            <Tooltip
              title={textOnHoverNew[product.new_status]}
              arrow
              placement="top"
              fontColor="#0b1829"
              backgroundColor="white"
            >
              <StatusChip
                label={PRODUCT_STATUS_OPTIONS[product.new_status]}
                status={proofStatusStylesNew[product.new_status]}
              />
            </Tooltip>
          </Grid>
          <Grid item xs>
            <p className={classes.designType}>{isPack(product.record_type) ? '📦 Pack' : '👕 Product'}</p>
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid className={classes.imageContainer}>
            <div className={classes.productImageContainer}>
              <Img
                src={productImageBasedOnStatus(product, 256, 256)}
                alt={product.name}
                className={classes.designImage}
              />
              <Grid
                id="zoom-container"
                container
                justifyContent="center"
                alignItems="center"
                className="zoom"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  display: 'none',
                  background: 'rgba(255, 255, 255, 0.5)'
                }}
                onClick={({ target: { id } }) => (id?.includes('zoom-') ? setViewedProduct(product) : false)}
              >
                <Grid id="zoom-wrapper" item>
                  <img
                    id="zoom-image"
                    src="/images/redeem/zoom-in.svg"
                    className={classes.productZoomImage}
                    alt="zoom"
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div style={{ margin: '12px 0px 4px 0px' }}>
            <EditField
              value={product.name}
              onEdit={name => editNameMutation.mutate({ name })}
              isLoading={editNameMutation.isLoading}
              data-testid={`editfield-${product.id}`}
            />
          </div>
          <Grid container alignItems="center">
            <Grid item>
              <Grid container spacing={1} alignItems="center">
                {showDiscount && (
                  <>
                    <Grid item>
                      <DiscountBagde discount={discount} />
                    </Grid>
                    <Grid item>
                      {discount > 0 ? (
                        <StrikeOutText value={product.price_per_unit_based_on_100_without_discount} fontSize={12} />
                      ) : null}
                    </Grid>
                  </>
                )}
                <Grid item>
                  <p className={classes.designPrice}>
                    {moneyStr(product.price_per_unit_based_on_100)}
                    <span>/unit*</span>
                  </p>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <div className={classes.designPriceInfo}>
                <Tooltip
                  interactive
                  title={priceInfo}
                  placement="top"
                  width={227}
                  backgroundColor="white"
                  enterTouchDelay={50}
                  leaveTouchDelay={5000}
                >
                  <InfoOutlined className={classes.info} data-testid="price-info-icon" />
                </Tooltip>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid container alignItems="center" style={{ marginTop: 12 }}>
          <Grid item xs>
            <USShippingOnlyTag product={product} hideAll />
            <Button variant="text" component={Link} to={`/product-details/${product.id}`} className={classes.link}>
              View Mockups
              <ChevronRight className={classes.linkIcon} />
            </Button>
          </Grid>
          <Grid item>
            <CustomTooltip title={hasEmptyStock ? 'Your pack is generating, please wait.' : ''}>
              <IconButton
                aria-label={imgAlt}
                className={classes.addButton}
                onClick={onSelect}
                disabled={hasEmptyStock || isSelected}
              >
                <img src={imgSrc} alt={imgAlt} />
              </IconButton>
            </CustomTooltip>
          </Grid>
        </Grid>
      </Grid>
    </StandardCard>
  );
};

const useActionButtonStyles = makeStyles(theme => ({
  buttonRoot: {
    display: 'inline-block',
    margin: '0 20px',
    color: theme.palette.grey['900'],
    '&:hover, &:focus-visible': { color: theme.palette.primary.main },
    '&:active': { color: theme.palette.primary['800'] },
    '&.Mui-disabled, &.Mui-disabled > p': { color: theme.palette.grey['400'] }
  },
  iconsContainer: {
    position: 'relative',
    marginBottom: 4
  },
  baseIcon: {
    fontSize: 20,
    opacity: 1,
    'button:hover &, button:focus-visible &': { opacity: 0 }
  },
  hoverIcon: {
    fontSize: 20,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
    opacity: 0,
    'button:hover &, button:focus-visible &': { opacity: 1 }
  }
}));

const ActionButton = ({ text, disabled, onClick, BaseIcon, HoverIcon }) => {
  const classes = useActionButtonStyles();
  const DerivedHoverIcon = HoverIcon ?? BaseIcon;

  return (
    <ButtonBase onClick={onClick} disabled={disabled} disableRipple classes={{ root: classes.buttonRoot }}>
      <Grid container justifyContent="center" className={classes.iconsContainer}>
        <BaseIcon className={classes.baseIcon} />
        <DerivedHoverIcon className={classes.hoverIcon} />
      </Grid>
      <Typography variant="body3MediumInter">{text}</Typography>
    </ButtonBase>
  );
};

const useActionBarStyles = makeStyles(theme => ({
  snackbarContent: {
    minWidth: 'max-content',
    borderRadius: 8,
    padding: '14px 32px',
    background: '#FFFFFF',
    boxShadow: '0px 20px 40px 2px rgba(0, 0, 0, 0.12)'
  },
  itemsSelectedText: {
    margin: '0 10px',
    color: '#131415',
    minWidth: 116,
    fontVariantNumeric: 'tabular-nums'
  },
  itemsSelectedTextWidth: {
    width: 200
  },
  actionsContainer: {
    display: 'flex',
    margin: '0 20px'
  },
  clearIcon: {
    fontSize: 24,
    color: '#131415',
    background: 'transparent !important',
    padding: 0,
    transition: 'opacity 150ms ease',
    '&:hover, &:focus-visible': { color: theme.palette.primary.main },
    '&:active': { color: theme.palette.primary['800'] }
  },
  actionBar: {
    bottom: 32
  }
}));

const buildOpportunityPayload = accountProducts => ({
  account_products: accountProducts.map(ap => {
    const firstActiveSize = ap.stock?.find(s => s.active);
    return {
      account_product: ap.id,
      production_time: productionTime.standard,
      quantities_per_size: ap.stock.map(({ size }) => ({
        size: size.id,
        quantity: firstActiveSize?.size.id === size.id ? 100 : 0
      }))
    };
  }),
  lead_source: 'Products Tab'
});

const ActionBar = ({
  selectedProducts,
  openDeleteModal,
  isDeletingProducts,
  onClear,
  setOpenDeleteModal,
  isInsideDrawer,
  closeDrawer
}) => {
  const totalSelected = selectedProducts.size;
  const selectedMsg = totalSelected === 1 ? '' : 's';
  const classes = useActionBarStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { productsFeEnableDeleteFunctionalityTemp081022: enableDelete, leftBarNavigation } = useFlags();
  const { id } = useParams();
  const opportunity = useSelector(state => state.opportunity);

  const handelCloseDrawer = () => {
    closeDrawer();
  };

  const dispatchSuccess = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.success, message } });
  const dispatchError = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.error, message } });
  const dispatchLoading = message => dispatch({ type: STATUS_CHANGED, payload: { status: statuses.pending, message } });

  const { mutate: createOpportunity, isLoading: isCreatingPack } = useMutation(orderApi.createOpportunity, {
    onSuccess: opportunity => {
      dispatchSuccess('Order successfully created');
      history.push({
        pathname: `/orders-requested/${opportunity.id}`,
        state: { fromProducts: true, sendProductsToWarehouse: true }
      });
    },
    onError: () => dispatchError('Order creation failed')
  });

  const { mutate: patchOpportunity, isLoading: isUpdatingOrder } = useMutation(
    data => orderApi.patchOpportunity(id, data),
    {
      onSuccess: () => {
        dispatchSuccess('Product successfully Added');
        queryClient.invalidateQueries([apiPaths.opportunities, +id]);
      },
      onError: () => dispatchError('Adding Product failed')
    }
  );

  const queryClient = useQueryClient();
  const { mutate: createPack, isLoading: isBuildingPack } = useMutation(accountProductsApi.createAccountProducts, {
    onSuccess: response => {
      dispatchSuccess('Pack successfully created');
      queryClient.invalidateQueries(apiPaths.accountProducts);
      onClear({ pathname: `/product-details/${response.id}`, search: 'record_type=pack' });
    },
    onError: () => dispatchError('Pack creation failed')
  });

  const amountSelectedByType = countBy([...selectedProducts.values()], item => item.record_type);
  const hasMoreThanOneBulkItem = amountSelectedByType.Product > 1;
  const hasPacks = amountSelectedByType.Pack > 0;
  const disableBuildPack = !hasMoreThanOneBulkItem || hasPacks;

  const handleOnCreateOrder = () => {
    if (totalSelected === 0) return;

    gtm.onClick('New Order');
    dispatch({
      type: STATUS_CHANGED,
      payload: { status: statuses.pending, message: 'Creating order now' }
    });
    createOpportunity(buildOpportunityPayload([...selectedProducts.values()]));
  };

  const handleAddProductToExistingOrder = async () => {
    dispatchLoading('Updating order...');
    patchOpportunity(buildOpportunityPayload([...selectedProducts.values()]));
    handleClear();
    handelCloseDrawer();

    // try to call fetch opportunity here only may be it will get updated
  };

  const handleOnBuildPack = () => {
    if (disableBuildPack) return;

    const hazardousProducts = [...selectedProducts.values()].filter(p => p.hazardous_materials);

    if (hazardousProducts.length > 1) {
      dispatchError(
        `You cannot create a pack with more than 1 hazmat product: \n${hazardousProducts.map(p => p.name).join(', ')}`
      );
      // setTimeout(() => dispatchLoading(), 5000);
      return;
    }

    gtm.onClick('New Pack');
    dispatch({
      type: STATUS_CHANGED,
      payload: { status: statuses.pending, message: 'Combining items into pack' }
    });
    const today = new Date();

    createPack({
      name: `Pack ${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`,
      pack_items: [...selectedProducts.values()].map(p => ({
        account_product: p.id,
        units_per_pack: 1,
        visible_in_inventory: false
      }))
    });
  };

  const handleClear = () => {
    onClear();
    setOpenDeleteModal(false);
  };

  const builPackDisabledText =
    (hasPacks && 'Only individual products can be selected to create a pack') ||
    (!hasMoreThanOneBulkItem && 'You must select more than one product to build a pack');

  const deleteModalDisableText = openDeleteModal && 'You must close the Delete Dialog to select another option';

  return (
    <Snackbar
      open={totalSelected > 0 && !isCreatingPack && !isBuildingPack && !isDeletingProducts && !isUpdatingOrder}
      TransitionComponent={Slide}
      transitionDuration={{ enter: 200, exit: 150 }}
      className={classes.actionBar}
    >
      <Grid container justifyContent="space-between" alignItems="center" className={classes.snackbarContent}>
        <Typography
          variant="body3SemiBoldInter"
          className={isInsideDrawer ? classes.itemsSelectedTextWidth : classes.itemsSelectedText}
        >
          {totalSelected} item{selectedMsg} selected
        </Typography>
        {isInsideDrawer ? (
          <div className={classes.actionsContainer}>
            <CustomTooltip title={deleteModalDisableText}>
              <ActionButton
                text="Add to Order"
                onClick={handleAddProductToExistingOrder}
                disabled={totalSelected === 0 || openDeleteModal}
                BaseIcon={ShoppingCartOutlined}
                HoverIcon={ShoppingCart}
              />
            </CustomTooltip>
          </div>
        ) : (
          <div className={classes.actionsContainer}>
            <CustomTooltip title={deleteModalDisableText}>
              <ActionButton
                text="Create Order"
                onClick={handleOnCreateOrder}
                disabled={totalSelected === 0 || openDeleteModal}
                BaseIcon={ShoppingCartOutlined}
                HoverIcon={ShoppingCart}
              />
            </CustomTooltip>
            <CustomTooltip title={deleteModalDisableText || builPackDisabledText}>
              <ActionButton
                text="Build Pack"
                onClick={handleOnBuildPack}
                disabled={disableBuildPack || openDeleteModal}
                BaseIcon={CallMergeOutlined}
                HoverIcon={CallMerge}
              />
            </CustomTooltip>
            {enableDelete && (
              <CustomTooltip title={deleteModalDisableText}>
                <ActionButton
                  text="Delete"
                  onClick={() => setOpenDeleteModal(true)}
                  disabled={totalSelected === 0 || openDeleteModal}
                  BaseIcon={DeleteOutline}
                  HoverIcon={Delete}
                />
              </CustomTooltip>
            )}
          </div>
        )}
        <IconButton
          aria-label="cancel"
          disableRipple
          disabled={totalSelected === 0}
          onClick={handleClear}
          className={classes.clearIcon}
        >
          <Close />
        </IconButton>
      </Grid>
    </Snackbar>
  );
};

const useDeleteModalStyles = makeStyles({
  paper: {
    width: 600,
    borderRadius: 8,
    '& .MuiDialogContent-root': { padding: 0 },
    '& .MuiDialogTitle-root': { margin: '8px 0px' }
  },
  buttonsContainer: { borderTop: '1px solid #E5E7E8', padding: '12px 24px' },
  cancelButton: { '&:hover': { color: changeColorLightness('#3577d4') } }
});

const DeleteModal = ({ open, totalSelected, onDelete, onClose }) => {
  const classes = useDeleteModalStyles();
  const selectedMsg = totalSelected === 1 ? '' : 's';
  const title = `Delete ${totalSelected} product${selectedMsg}?`;
  const subtitle = `You cannot restore ${totalSelected === 1 ? 'a' : ''} deleted product${selectedMsg}.`;

  return (
    <Dialog classes={classes} open={open} onClose={onClose}>
      <DialogTitle>
        <Typography variant="body2SemiBoldInter" style={{ marginBottom: 16 }}>
          {title}
        </Typography>
        <Typography variant="body3RegularInter">{subtitle}</Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container justifyContent="flex-end" alignItems="center" className={classes.buttonsContainer}>
          <Button size="small" variant="text" onClick={onClose} className={classes.cancelButton}>
            Cancel
          </Button>
          <Button size="small" variant="primary" onClick={onDelete} style={{ height: 40, width: 84 }}>
            Delete
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export {
  ProductsHeader,
  Filters,
  ActionBar,
  ProductCard,
  FilterBy,
  SearchField,
  SortBy,
  DeleteModal,
  CustomTooltip,
  CustomCheckIcon
};
