import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Typography } from '@swagup-com/components';
import { LocalShipping } from '@material-ui/icons';

const useStyles = makeStyles({
  tagText: { color: '#EF6C00', display: ({ hide }) => (hide ? 'none' : undefined) },
  tagIcon: { color: '#EF6C00', fontSize: 16, marginBottom: -5, display: ({ hide }) => (hide ? 'none' : undefined) },
  tagBox: { height: 28, display: ({ hideAll, hide }) => (hide && hideAll ? 'none' : undefined) }
});

const USShippingOnlyTag = ({ product, hideAll, style }) => {
  const classes = useStyles({ hide: product?.can_ship_international, hideAll });

  return (
    <Grid container alignItems="center" spacing={2} className={classes.tagBox} style={style}>
      <Grid item>
        <LocalShipping className={classes.tagIcon} />
      </Grid>
      <Grid item xs>
        <Typography variant="body3RegularInter" className={classes.tagText}>
          US Shipping Only
        </Typography>
      </Grid>
    </Grid>
  );
};

export default USShippingOnlyTag;
